div.pkcp {
  user-select: none !important;
}
div.pkcp {
  --pkcp-font-size: 15px;
  --pkcp-background-color: #f0f0f0;
  --pkcp-foreground-color: #202122;
  --pkcp-border-color: #858585;
  --pkcp-transition-duration: 0.5s;
  --pkcp-border-radius: 5px;
  --pkcp-banner-title-font-size: calc(var(--pkcp-font-size) + 3px);
  --pkcp-banner-description-font-size: var(--pkcp-font-size);
  --pkcp-button-font-size: var(--pkcp-font-size);
  --pkcp-popup-header-font-size: calc(var(--pkcp-font-size) + 6px);
  --pkcp-popup-navbar-main-font-size: calc(var(--pkcp-font-size) + 1px);
  --pkcp-popup-navbar-sub-font-size: var(--pkcp-font-size);
  --pkcp-popup-category-title-font-size: calc(var(--pkcp-font-size) + 15px);
  --pkcp-popup-category-description-font-size: var(--pkcp-font-size);
  --pkcp-popup-provider-title-font-size: calc(var(--pkcp-font-size) + 9px);
  --pkcp-popup-section-title-font-size: calc(var(--pkcp-font-size) + 3px);
  --pkcp-popup-section-description-font-size: var(--pkcp-font-size);
  --pkcp-popup-table-header-font-size: calc(var(--pkcp-font-size) + 1px);
  --pkcp-popup-table-body-font-size: var(--pkcp-font-size);
  --pkcp-banner-background: var(--pkcp-background-color);
  --pkcp-popup-background: var(--pkcp-background-color);
  --pkcp-overlay-background: rgba(0,0,0,0.5);
  --pkcp-banner-overlay-background: rgba(0,0,0,0.3);
  --pkcp-banner-text-color: var(--pkcp-foreground-color);
  --pkcp-popup-text-color: var(--pkcp-foreground-color);
  --pkcp-popup-menu-button-color: var(--pkcp-foreground-color);
  --pkcp-popup-sidebar-border-color: var(--pkcp-border-color);
  --pkcp-popup-header-border-color: var(--pkcp-border-color);
  --pkcp-popup-footer-border-color: var(--pkcp-border-color);
  --pkcp-popup-border-radius: var(--pkcp-border-radius);
  --pkcp-revoke-border-radius: var(--pkcp-border-radius);
  --pkcp-banner-transition-duration: var(--pkcp-transition-duration);
  --pkcp-banner-overlay-transition-duration: var(--pkcp-transition-duration);
  --pkcp-button-accept-background: #317a42;
  --pkcp-button-accept-background-hover: #378f4b;
  --pkcp-button-accept-color: #f0f0f0;
  --pkcp-button-accept-border-width: 0;
  --pkcp-button-accept-border-color: transparent;
  --pkcp-button-settings-background: #378a97;
  --pkcp-button-settings-background-hover: #3c9ead;
  --pkcp-button-settings-color: #f0f0f0;
  --pkcp-button-settings-border-width: 0;
  --pkcp-button-settings-border-color: transparent;
  --pkcp-button-decline-background: #858585;
  --pkcp-button-decline-background-hover: #999999;
  --pkcp-button-decline-color: #f0f0f0;
  --pkcp-button-decline-border-width: 0;
  --pkcp-button-decline-border-color: transparent;
  --pkcp-button-privacy-background: transparent;
  --pkcp-button-privacy-background-hover: transparent;
  --pkcp-button-privacy-color: var(--pkcp-foreground-color, #212529);
  --pkcp-button-privacy-border-width: 1px;
  --pkcp-button-privacy-border-color: var(--pkcp-foreground-color, #212529);
  --pkcp-button-save-background: var(--pkcp-button-settings-background, #378a97);
  --pkcp-button-save-background-hover: var(--pkcp-button-settings-background-hover, #3c9ead);
  --pkcp-button-save-color: #f0f0f0;
  --pkcp-button-save-border-width: 0;
  --pkcp-button-save-border-color: transparent;
  --pkcp-button-abort-background: var(--pkcp-button-decline-background, #858585);
  --pkcp-button-abort-background-hover: var(--pkcp-button-decline-background-hover, #999999);
  --pkcp-button-abort-color: #f0f0f0;
  --pkcp-button-abort-border-width: 0;
  --pkcp-button-abort-border-color: transparent;
}
div.pkcp.theme-dark, div.pkcp.theme-default {
  --pkcp-background-color: #495057;
  --pkcp-foreground-color: #f0f0f0;
  --pkcp-border-color: #858585;
  --pkcp-popup-sidebar-background: #223545;
}
div.pkcp .hidden {
  display: none !important;
}
div.pkcp > .pkcp-overlay {
  z-index: 5000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--pkcp-overlay-background, rgba(0, 0, 0, 0.5));
  display: none;
}
div.pkcp > .pkcp-banner {
  z-index: 6000;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  gap: 15px;
  max-height: 99vh;
  background-color: var(--pkcp-banner-background, #f8f9fa);
  color: var(--pkcp-banner-text-color, #212529);
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-banner {
    flex-direction: row;
  }
}
div.pkcp > .pkcp-banner.position-bottom {
  bottom: -100vh;
  box-shadow: 0 1px 15px -1px rgba(0, 0, 0, 0.9);
  transition: bottom var(--pkcp-banner-transition-duration, 0.5s) ease-in-out;
}
div.pkcp > .pkcp-banner.position-bottom.in {
  bottom: 0 !important;
}
div.pkcp > .pkcp-banner.position-top {
  top: -100vh;
  box-shadow: 0 -1px 15px -1px rgba(0, 0, 0, 0.9);
  transition: top var(--pkcp-banner-transition-duration, 0.5s) ease-in-out;
}
div.pkcp > .pkcp-banner.position-top.in {
  top: 0 !important;
}
div.pkcp > .pkcp-banner::after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color var(--pkcp-banner-overlay-transition-duration, 0.5s) ease;
}
div.pkcp > .pkcp-banner.overlayed::after {
  background-color: var(--pkcp-banner-overlay-background, rgba(0, 0, 0, 0.3));
  pointer-events: all;
}
div.pkcp > .pkcp-banner > .content {
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
  overflow-y: auto;
}
div.pkcp > .pkcp-banner > .content > p {
  margin-bottom: 0;
}
div.pkcp > .pkcp-banner > .content > p > .title, div.pkcp > .pkcp-banner > .content > p > .title > * {
  font-weight: bold;
  font-size: var(--pkcp-banner-title-font-size, 12pt);
  color: var(--pkcp-banner-text-color, #212529);
}
div.pkcp > .pkcp-banner > .content > p > .description, div.pkcp > .pkcp-banner > .content > p > .description > * {
  font-size: var(--pkcp-banner-description-font-size, 11pt);
  color: var(--pkcp-banner-text-color, #212529);
}
div.pkcp > .pkcp-banner > .buttons {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 7px;
  width: 100%;
  max-width: 400px;
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-banner > .buttons {
    min-width: 200px;
    width: 10%;
  }
}
div.pkcp > .pkcp-banner > .buttons > a {
  width: 100%;
}
div.pkcp > .pkcp-banner > .buttons button.pkcp-btn {
  width: 100%;
}
div.pkcp > .pkcp-banner > .buttons button.pkcp-btn:hover {
  transform: scale(1.05);
}
div.pkcp > .pkcp-details {
  z-index: 7000;
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 30px;
  right: 30px;
  margin: 0 auto;
  max-width: 700px;
  min-height: min(100vh, 600px);
  background-color: var(--pkcp-popup-background, #f8f9fa);
  color: var(--pkcp-popup-text-color, #212529);
  border-radius: var(--pkcp-popup-border-radius, 5px);
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.8);
  display: none;
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-details {
    top: min(10%, 100px);
    bottom: min(10%, 100px);
    left: min(10%, 200px);
    right: min(10%, 200px);
    max-width: 1000px;
  }
}
@media (min-width: 992px) {
  div.pkcp > .pkcp-details {
    bottom: min(15%, 120px);
    left: min(12%, 200px);
    right: min(12%, 200px);
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  div.pkcp > .pkcp-details {
    top: min(10%, 150px);
    left: min(15%, 200px);
    right: min(15%, 200px);
    bottom: min(15%, 150px);
  }
}
div.pkcp > .pkcp-details > .inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
div.pkcp > .pkcp-details > .inner > .header {
  height: 50px;
  padding: 5px;
  padding-left: 1rem;
  border-bottom: 1px solid var(--pkcp-popup-header-border-color);
  z-index: 150;
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle {
  display: inline-block;
  vertical-align: text-top;
  margin-top: 2px;
  margin-right: 7px;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none !important;
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle > div {
  height: 5px;
  width: 25px;
  background-color: var(--pkcp-popup-menu-button-color);
  margin: 3px 0;
  transition: all 0.3s ease;
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle > div:nth-of-type(1) {
  transform: translateY(0px) rotate(0deg);
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle > div:nth-of-type(2) {
  opacity: 1;
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle > div:nth-of-type(3) {
  transform: translateY(0px) rotate(0deg);
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle.open > div:nth-of-type(1) {
  transform: translateY(8px) rotate(45deg);
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle.open > div:nth-of-type(2) {
  opacity: 0;
}
div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle.open > div:nth-of-type(3) {
  transform: translateY(-8px) rotate(-45deg);
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-details > .inner > .header > button.navbar-toggle {
    display: none;
  }
}
div.pkcp > .pkcp-details > .inner > .header > .title {
  font-size: var(--pkcp-popup-header-font-size, 15pt);
  line-height: calc(var(--pkcp-popup-header-font-size) * 1.9);
  font-weight: bold;
  vertical-align: text-top;
  color: var(--pkcp-popup-text-color, #212529);
}
div.pkcp > .pkcp-details > .inner > .header > button.close {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 22pt;
  transition: opacity 0.3s ease;
  color: var(--pkcp-popup-text-color, #212529);
}
div.pkcp > .pkcp-details > .inner > .main {
  position: relative;
  display: flex;
  overflow: hidden;
  max-height: calc(100% - 175px);
  box-shadow: inset 0 0px 5px -2px rgba(0, 0, 0, 0.7);
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-details > .inner > .main {
    max-height: calc(100% - 100px);
  }
}
div.pkcp > .pkcp-details > .inner > .main > * {
  overflow-y: auto;
  height: 100%;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px 5px;
  border-right: 1px solid var(--pkcp-popup-sidebar-border-color);
  width: 30%;
  z-index: 100;
  min-width: 250px;
  max-width: 100%;
  position: absolute;
  background-color: var(--pkcp-popup-sidebar-background, var(--pkcp-popup-background, #f8f9fa));
  transition: left 0.3s ease;
  left: -100%;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar.in {
  left: 0;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar.in + .content::before {
  background: rgba(0, 0, 0, 0.3) !important;
  pointer-events: all !important;
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-details > .inner > .main > nav.sidebar {
    position: initial;
    left: unset !important;
    max-width: 300px;
  }
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul {
  padding-left: 0;
  list-style: none;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul {
  padding-left: 0;
  list-style: none;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li {
  position: relative;
  padding-left: 25px;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon {
  position: absolute;
  left: 0;
  top: -1px;
  height: 20px;
  width: 20px;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg {
  fill: var(--pkcp-popup-text-color, var(--pkcp-foreground-color, #212529));
  vertical-align: top;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg circle,
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg ellipse,
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg line,
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg path,
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg polygon,
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg polyline,
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar ul ul > li > .mock-list-icon > svg rect {
  shape-rendering: geometricPrecision;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category {
  margin-bottom: 15px;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title {
  padding-left: 0;
  margin-bottom: 3px;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label {
  margin-left: 0;
  margin-bottom: 3px;
  user-select: none;
  font-weight: bold;
  font-size: var(--pkcp-popup-navbar-font-size);
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > .pkcp-checkbox {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: sub;
  height: 20px;
  width: 20px;
  background-color: var(--pkcp-checkbox-hover-background, #eee);
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > .pkcp-checkbox::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > input.form-check-input {
  display: none;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > input.form-check-input:checked ~ .pkcp-checkbox {
  background-color: var(--pkcp-checkbox-checked-background, var(--pkcp-button-settings-background, #378a97));
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > input.form-check-input:checked ~ .pkcp-checkbox::after {
  display: block;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > input.form-check-input:disabled ~ .pkcp-checkbox {
  cursor: not-allowed;
  background-color: var(--pkcp-checkcox-disabled-background, var(--pkcp-button-decline-background, #757575));
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label > a {
  color: var(--pkcp-popup-text-color, #212529);
  font-size: var(--pkcp-popup-navbar-main-font-size);
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category div.title > label.form-check-label:hover > .pkcp-checkbox {
  background-color: var(--pkcp-checkbox-hover-background, #ccc);
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category > .subentries > ul > li.provider {
  margin-bottom: 2px;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category > .subentries > ul > li.provider > a {
  color: var(--pkcp-popup-text-color, #212529);
  font-size: var(--pkcp-popup-navbar-sub-font-size);
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category > .subentries > ul > li.provider > a > span {
  vertical-align: text-top;
}
div.pkcp > .pkcp-details > .inner > .main > nav.sidebar li.category > .subentries > hr {
  margin-left: -1.5rem;
}
div.pkcp > .pkcp-details > .inner > .main > .content {
  padding: 10px 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page {
  margin-bottom: 11%;
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page:last-of-type {
  margin-bottom: 33%;
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .title h2 {
  font-size: var(--pkcp-popup-category-title-font-size, 30px);
  font-weight: bold;
  color: var(--pkcp-popup-text-color, #212529);
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .description p {
  font-size: var(--pkcp-popup-category-description-font-size, 30px);
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > h3 {
  font-size: var(--pkcp-popup-provider-title-font-size, 24px);
  color: var(--pkcp-popup-text-color, #212529);
  margin-bottom: 1em;
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information h4 {
  font-size: var(--pkcp-popup-section-title-font-size, 18px);
  color: var(--pkcp-popup-text-color, #212529);
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information p {
  font-size: var(--pkcp-popup-section-description-font-size, 30px);
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table {
  margin-bottom: 1.5em;
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > thead > tr > th,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > thead > tr > th,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > thead > tr > th,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > thead > tr > th {
  font-size: var(--pkcp-popup-table-header-font-size, 16px);
  font-weight: bold;
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td,
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td {
  font-size: var(--pkcp-popup-table-body-font-size, 16px);
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td:nth-of-type(1),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td:nth-of-type(1),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td:nth-of-type(1),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td:nth-of-type(1) {
  width: 25%;
}
@media (min-width: 992px) {
  div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td:nth-of-type(1),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td:nth-of-type(1),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td:nth-of-type(1),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td:nth-of-type(1) {
    width: 20%;
  }
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td:nth-of-type(2),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td:nth-of-type(2),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td:nth-of-type(2),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td:nth-of-type(2) {
  width: 25%;
}
@media (min-width: 992px) {
  div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td:nth-of-type(2),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td:nth-of-type(2),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td:nth-of-type(2),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td:nth-of-type(2) {
    width: 20%;
  }
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td:nth-of-type(3),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td:nth-of-type(3),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td:nth-of-type(3),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td:nth-of-type(3) {
  width: 50%;
}
@media (min-width: 992px) {
  div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .cookies table.table > tbody > tr > td:nth-of-type(3),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .session-storage table.table > tbody > tr > td:nth-of-type(3),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .local-storage table.table > tbody > tr > td:nth-of-type(3),
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > .provider-information > .indexed-db table.table > tbody > tr > td:nth-of-type(3) {
    width: 60%;
  }
}
div.pkcp > .pkcp-details > .inner > .main > .content .category-page > hr {
  margin: 25px 0 20px;
}
div.pkcp > .pkcp-details > .inner > .main > .content::before {
  content: " ";
  background: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
div.pkcp > .pkcp-details > .inner > .footer {
  border-top: 1px solid var(--pkcp-popup-footer-border-color);
  height: 130px;
  padding: 5px;
  z-index: 200;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-details > .inner > .footer {
    height: 50px;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
div.pkcp > .pkcp-details > .inner > .footer > button.pkcp-btn {
  width: 100%;
}
@media (min-width: 768px) {
  div.pkcp > .pkcp-details > .inner > .footer > button.pkcp-btn {
    width: auto;
    flex-grow: 1;
  }
}
div.pkcp > .pkcp-details > .inner > .footer > button.pkcp-btn:hover {
  transform: scale(1.02);
}
div.pkcp > .pkcp-details > .inner .table {
  color: var(--pkcp-popup-text-color, var(--pkcp-foreground-color, #212529));
}
div.pkcp > .pkcp-revoke {
  z-index: 8000;
  position: fixed;
  width: 50px;
  height: 50px;
  left: 15px;
  bottom: 15px;
}
div.pkcp > .pkcp-revoke > button.btn {
  padding: 5px 3px;
  background-color: white;
}
div.pkcp > .pkcp-revoke > button.btn > img {
  max-width: 100%;
  width: auto;
  /*filter: drop-shadow(1px 2px 3px black);*/
}
div.pkcp button.pkcp-btn {
  font-size: var(--pkcp-button-font-size, 11pt);
  font-weight: bold;
  text-align: center;
  transform: scale(1);
  transition: all 0.3s ease;
  text-rendering: geometricPrecision;
}
div.pkcp button.pkcp-btn.pkcp-btn-accept {
  background-color: var(--pkcp-button-accept-background, #317a42);
  color: var(--pkcp-button-accept-color, white);
  border: var(--pkcp-button-accept-border-width, 0) solid;
  border-color: var(--pkcp-button-accept-border-color, transparent);
}
div.pkcp button.pkcp-btn.pkcp-btn-accept:hover {
  background-color: var(--pkcp-button-accept-background-hover, #35a04e);
}
div.pkcp button.pkcp-btn.pkcp-btn-settings {
  background-color: var(--pkcp-button-settings-background, #378a97);
  color: var(--pkcp-button-settings-color, white);
  border: var(--pkcp-button-settings-border-width, 0) solid;
  border-color: var(--pkcp-button-settings-border-color, transparent);
}
div.pkcp button.pkcp-btn.pkcp-btn-settings:hover {
  background-color: var(--pkcp-button-settings-background-hover, #3eadbe);
}
div.pkcp button.pkcp-btn.pkcp-btn-decline {
  background-color: var(--pkcp-button-decline-background, #858585);
  color: var(--pkcp-button-decline-color, #212529);
  border: var(--pkcp-button-decline-border-width, 0) solid;
  border-color: var(--pkcp-button-decline-border-color, transparent);
}
div.pkcp button.pkcp-btn.pkcp-btn-decline:hover {
  background-color: var(--pkcp-button-decline-background-hover, #919191);
}
div.pkcp button.pkcp-btn.pkcp-btn-privacy {
  background-color: var(--pkcp-button-privacy-background, transparent);
  color: var(--pkcp-button-privacy-color, #212529);
  border: var(--pkcp-button-privacy-border-width, 1px) solid;
  border-color: var(--pkcp-button-privacy-border-color, #212529);
}
div.pkcp button.pkcp-btn.pkcp-btn-privacy:hover {
  background-color: var(--pkcp-button-privacy-background-hover, transparent);
}
div.pkcp button.pkcp-btn.pkcp-btn-save {
  background-color: var(--pkcp-button-save-background, var(--pkcp-button-settings-background, #378a97));
  color: var(--pkcp-button-save-color, var(--pkcp-button-settings-color, white));
  border: var(--pkcp-button-save-border-width, var(--pkcp-button-settings-border-width, 0)) solid;
  border-color: var(--pkcp-button-save-border-color, var(--pkcp-button-settings-border-color, transparent));
}
div.pkcp button.pkcp-btn.pkcp-btn-save:hover {
  background-color: var(--pkcp-button-save-background-hover, var(--pkcp-button-settings-background-hover, #3eadbe));
}
div.pkcp button.pkcp-btn.pkcp-btn-abort {
  background-color: var(--pkcp-button-abort-background, var(--pkcp-button-decline-background, #858585));
  color: var(--pkcp-button-abort-color, var(--pkcp-button-decline-color, #212529));
  border: var(--pkcp-button-abort-border-width, var(--pkcp-button-decline-border-width, 0)) solid;
  border-color: var(--pkcp-button-abort-border-color, var(--pkcp-button-decline-border-color, transparent));
}
div.pkcp button.pkcp-btn.pkcp-btn-abort:hover {
  background-color: var(--pkcp-button-abort-background-hover, var(--pkcp-button-decline-background-hover, #919191));
}